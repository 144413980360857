export const enterpriseColumns = [
  { field: "id", headerName: "ID", width: 70 },

  
  {
    field: "user_name",
    headerName: "User Name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "created_at",
    headerName: "Registration Date",
    width: 180,
  },
  {
    field: "role",
    headerName: "Occupation",
    width: 130,
  },
  {
    field: "is_active",
    headerName: "Status",
    width: 160,
  },
];